import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';
import {
  getId,
  getInvestmentReason,
  getInvestorConsent,
  getKybStage,
  getMonthlyAmount,
  getMonthlyOperations,
  getOperationReason,
  KYBStagesEnum,
  trimTextToWords,
} from 'containers/UserPages/KYBMoralPages/pages/DetailPage/helpers';
import BlankInformation from 'images/blank-information.png';
import { useTranslation } from 'react-i18next';
import { IKYBMoralPersonResource } from 'services/resources/users/kyb-moral/types.d';

import { EmptyBanner } from './EmptyBanner';
import { Record } from './Record';

export interface IOperationInformationProps extends BoxProps {
  /**
   * KYC physical user resource data.
   */
  kyb?: IKYBMoralPersonResource;
}

export const OperationInformation: React.FC<IOperationInformationProps> = (
  props,
): JSX.Element => {
  const { kyb, ...rest } = props;

  console.log('operational information kyb', kyb);

  const { t } = useTranslation('kyc-physical');

  if (getKybStage(kyb) < KYBStagesEnum.BUSINESS_BANK_INFORMATION_SUCESS) {
    return (
      <EmptyBanner
        cover={BlankInformation}
        mb={6}
        title={t('page.detail.address.empty.title')}
      />
    );
  }

  return (
    <Box {...rest}>
      <SimpleGrid columns={[1, 1, 2, 3]} spacingY={6}>
        <Record
          description={trimTextToWords(getInvestmentReason(kyb), 13)}
          id={getId(kyb)}
          isEditable
          name="investment_reason"
          title={t('page.detail.operation.investment-reason.title')}
        />

        <Record
          description={getInvestorConsent(kyb)}
          id={getId(kyb)}
          isEditable
          name="investment_consent"
          title={t('page.detail.operation.investor-consent.title')}
        />

        <Record
          description={getOperationReason(kyb)}
          id={getId(kyb)}
          isEditable
          name="operation_reason"
          title={t('page.detail.operation.reason.title')}
        />

        <Record
          description={getMonthlyAmount(kyb)}
          id={getId(kyb)}
          isEditable
          name="monthly_amount"
          title={t('page.detail.operation.monthly-amount.title')}
        />

        <Record
          description={getMonthlyOperations(kyb)}
          id={getId(kyb)}
          isEditable
          name="monthly_operations"
          title={t('page.detail.operation.monthly-operations.title')}
        />
      </SimpleGrid>
    </Box>
  );
};
