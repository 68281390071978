import { Box, Image, Skeleton } from '@chakra-ui/react';
import { Divider, H2, Loading, PageHeadline } from 'components/elements';
import { KYBMoralUpdateForm } from 'components/forms';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import NotFoundCover from 'images/not-found.jpeg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { BankStatementResources } from 'services/resources/financial/bank-statements';
import { KYBMoralResources } from 'services/resources/users/kyb-moral';
import {
  IKYBMoralPersonPayload,
  IKYBMoralPersonResource,
} from 'services/resources/users/kyb-moral/types.d';

import { BankStatements } from './components/BankStatements';
import { CompanyInformation } from './components/CompanyInformation';
import { OfficialDocumentsInformation } from './components/OfficialDocumentsInformation';
import { OperationInformation } from './components/OperationInformation';
import { TaxInformation } from './components/TaxInformation';
import { getLegalSign } from './helpers';

export const DetailPage: React.FC = (): JSX.Element => {
  const { slug } = useParams<{ slug?: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation('kyb-moral');

  const { loading, value } = useAsync(async () => {
    const { data: kyb } = await KYBMoralResources.findByID(slug as string);
    const { data: statements } = await BankStatementResources.findAll({
      email: kyb?.user_detail?.email,
    });
    return { kyb, statements };
  }, [slug]);

  const handleOnSubmit = async ({ ...values }: IKYBMoralPersonPayload) => {
    try {
      await KYBMoralResources.update(value?.kyb?.id as number, values);
      dispatch(addSuccessToast('toast.success.network'));
    } catch (error) {
      dispatch(addErrorToast('toast.errors.network'));
    }
  };

  return (
    <>
      <PageHeadline
        quote={t('page.detail.quote')}
        title={t('page.detail.title', { trade_name: value?.kyb?.trade_name })}
      />
      <Divider mb={6} />
      {loading ? (
        <Loading isLoading />
      ) : (
        <Box px={8}>
          <H2>{t('page.detail.company-information.title')}</H2>
          <Divider mb={4} />
          <CompanyInformation
            mb={6}
            kyb={value?.kyb as IKYBMoralPersonResource}
          />

          <H2 mt={4}>{t('page.detail.company-bank-information.title')}</H2>
          <Divider mb={4} />
          <BankStatements mb={4} statements={value?.statements?.results} />

          <H2 mt={4}>{t('page.detail.company-tax-information.title')}</H2>
          <Divider mb={4} />
          <TaxInformation mb={4} kyb={value?.kyb} />

          <H2 mt={4}>{t('page.detail.company-official-documents.title')}</H2>
          <Divider mb={4} />
          <OfficialDocumentsInformation mb={4} kyb={value?.kyb} />

          <H2 mt={4}>{t('page.detail.company-legal-representative.title')}</H2>
          <Divider mb={4} />
          <Image
            alt="life-proof"
            fallback={<Skeleton h={32} rounded="md" w={32} />}
            fit="cover"
            mb={4}
            src={getLegalSign(value?.kyb) || NotFoundCover}
            rounded="md"
          />

          <H2 mt={4}>{t('page.detail.moral-update.title')}</H2>
          <Divider mb={4} />

          <H2>{t('page.detail.operation.title')}</H2>
          <Divider mb={4} />

          <OperationInformation kyb={value?.kyb} mb={6} />

          <KYBMoralUpdateForm
            initialValues={{
              is_accredited: value?.kyb?.is_accredited as boolean,
              is_active: value?.kyb?.is_active as boolean,
            }}
            onSubmit={handleOnSubmit}
          />
        </Box>
      )}
    </>
  );
};
